<template>
<div id="header">
  <nav class="navbar navbar-light navbar-expand-md fixed-top">
    <a class="navbar-brand" href="/">
      <img src="@/assets/img/logo.jpg" height="56px" />
      <span>泛函管理平台</span>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav_collapse" aria-controls="nav_collapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="nav_collapse">
      <ul class="navbar-nav">
        <!--<li class="nav-item mx-md-3">
          <a class="nav-link" href="/projects">项目</a>
        </li>
        <li class="nav-item mx-md-3">
          <a class="nav-link" href="/help">帮助</a>
        </li>-->
      </ul>
      <ul class="navbar-nav ml-auto" v-if="location==='login'">
        <form class="form-inline">
          <span class="navbar-text mr-1">还没账号？</span>
          <a class="btn btn-outline-primary btn-sm px-3 mr-3" href="/register" role="button">注册</a>
        </form>
      </ul>
      <ul class="navbar-nav ml-auto" v-else-if="location==='register'">
        <form class="form-inline">
          <span class="navbar-text mr-1">已有账号？</span>
          <a class="btn btn-outline-primary btn-sm px-3 mr-3" href="/login" role="button">登录</a>
        </form>
      </ul>
      <div class="navbar-nav ml-auto" v-else-if="isLogin">
        <el-button type="primary" size="small" @click="backTo" v-show="!currIndex">返回首页</el-button>
        <el-button type="danger" size="small" @click="logout">退出登录</el-button>
      </div>
      <!--<ul class="navbar-nav ml-auto" v-else-if="isLogin">
        <li class="nav-item mx-md-3">
          <a class="nav-link" href="/user/projects">工作台</a>
        </li>
        <li class="nav-item mx-md-3">
          <a class="nav-link" href="/user/info">个人中心</a>
        </li>
        <li class="nav-item dropdown mx-md-3">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img :src="user.avatar" rounded="circle" width="40px" height="40px" />
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <h6 class="dropdown-header">Hello, {{user.username}}</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="logout">退出登录</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" v-else>
        <li class="nav-item mx-md-3">
          <a class="nav-link" href="/login">登录</a>
        </li>
        <li class="nav-item mx-md-3">
          <a class="nav-link" href="/register">注册</a>
        </li>
      </ul>-->
    </div>
  </nav>
</div>
</template>

<script>
import { signOut, getUserInfo } from '@/api/login'
export default {
  name: 'adminHeader',
  props: ['location'],
  data () {
    return {
      isLogin: false,
      user: {}
    }
  },
  computed: {
    currIndex () {
      return this.$route.name === 'home'
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.current()
    })
  },
  methods: {
    current () {
      getUserInfo().then(
        res => {
          this.isLogin = true
          this.user = res.data
        }
      ).catch(
        err => {
          if (err.code) {
            this.isLogin = false
          }
        }
      )
    },
    logout () {
      signOut().then(
        res => {
          this.$notify.success({
            title: '成功',
            message: res.message
          })
          // 延时1000ms跳转
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    },
    backTo () {
      this.$router.push('/home')
    }
  }
}
</script>
<style scoped>
#header .navbar {
  background-color:#FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(31,45,61,.1);
  padding-top: 0rem;
  padding-bottom: 0rem;
}
#header .navbar-brand {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.nav-link.dropdown-toggle {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.btn-outline-secondary {
  border-color:#FFFFFF;
}
</style>
