import request from './index'

export function signIn (data) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data
  })
}

export function signOut () {
  return request({
    url: '/api/user/auth/logout',
    method: 'post'
  })
}

export function getUserInfo () {
  return request({
    url: '/api/user/current',
    method: 'get'
  }, false)
}
