import request from './index'

/**
 * 获取报表导航
 * @param {string} taskID
 */

export function GetProjects () {
  return request({
    url: '/api/project/list?status=1&page=1&pageSize=100',
    method: 'get'
  })
}

/**
 * 获取报表任务总览
 * @param {string} taskID
 */

export function projectStatus (taskID) {
  return request({
    url: `/api/report/task/${taskID}/status`,
    method: 'get'
  })
}

/**
 * 获取报表任务质检情况
 * @param {string} taskID
 */

// export const checkStatus = taskID => fetch(`/api/report/task/${taskID}/check`)

/**
 * 获取报表题目对比
 * @param {string} taskID
 */

export function quesDetails (taskID) {
  return request({
    url: `/api/report/task/${taskID}/question`,
    method: 'get'
  })
}

/**
 * 获取报表题目详细
 * @param {string} taskID
 */
export function sopDetails (taskID) {
  return request({
    url: `/api/report/task/${taskID}/sop`,
    method: 'get'
  })
}

/**
 * 获取报表用户信息
 * @param {string} taskID
 */
export function usersDetails (taskID, sTime, eTime) {
  return request({
    url: `/api/report/task/${taskID}/user?begin=${sTime}&end=${eTime}`,
    method: 'get'
  })
}
