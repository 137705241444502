import axios from 'axios'
import { Notification } from 'element-ui'

let errMessage = true
// 创建axios实例
const service = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 5000 // 请求超时时间
})

// request拦截器
// service.interceptors.request.use(config => {
//   // Do something before request is sent
//   if (store.getters.token) {
//     config.headers['X-Token'] = getToken() // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
//   }
//   return config
// }, error => {
//   // Do something with request error
//   console.log(error) // for debug
//   Promise.reject(error)
// })

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 0) {
      return res
    } else {
      if (errMessage) {
        Notification({
          message: res.message,
          type: 'error',
          duration: 2 * 1000
        })
      }
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err' + error)// for debug
    Notification({
      message: error.message,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  })

export default (request = {}, err = true) => {
  errMessage = err
  return service(request)
}
