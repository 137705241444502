<template>
<div class="main">
  <Header ref="header"/>
  <div class="report-box">
    <section class="report-nav">
      <el-menu
        class="h-100"
        unique-opened="true"
        @open="handleOpen"
        @close="handleClose"
        background-color="#2b333e"
        text-color="#fff"
        active-text-color="#13a2f9"
        style="border:none;">
        <el-submenu v-for="(task, index) in projects" :index="index" :key="index">
          <template slot="title">
            <div class="report-nav-title">{{task.name || task.id}}</div>
          </template>
          <el-menu-item-group>
            <template slot="title">{{task.id}}</template>
            <router-link :to="{ path: '/report/task', query: {id: task.id}}">
              <el-menu-item :index="index+'-0'">
                <i class="el-icon-document"></i>
                <span>任务总览</span>
              </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/report/user', query: {id: task.id}}">
              <el-menu-item :index="index+'-1'">
                <i class="el-icon-news"></i>
                <span>用户总览</span>
              </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/report/question', query: {id: task.id}}">
              <el-menu-item :index="index+'-2'">
                <i class="el-icon-tickets"></i>
                <span>任务情况</span>
              </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/report/sop', query: {id: task.id}}">
              <el-menu-item :index="index+'-3'">
                <i class="el-icon-tickets"></i>
                <span>任务详细</span>
              </el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </section>
    <section class="pl-5 pr-5 d-flex table-container">
      <router-view class="content"/>
      <Footer class="sub-footer"/>
    </section>
  </div>
</div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { GetProjects } from '@/api/report-task'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      projects: []
    }
  },
  created () {
    GetProjects().then(
      res => {
        this.projects = res.data.projects.map(
          item => {
            const { id, name } = item
            return {
              id: id,
              name: name
            }
          }
        )
      }
    )
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>

<style>
body {
  padding-top: 3.5rem;
}
.sub-footer  .navbar {
  background: #f3f5f7 !important;
}
</style>

<style lang="less" scoped>
.report-box {
  display: flex;
  align-items: stretch;
  height: calc(100vh - 56px);
  overflow: hidden;
}
.report-nav {
  width:20%;
  min-width: 200px;
  max-width:260px;
  background-color: #2b333e;
  overflow: hidden;
}
.report-nav:hover {
  overflow-y: auto;
}
.report-nav-title {
  max-width:200px;
  overflow:hidden;
  margin-right:1.5rem;
  text-overflow: ellipsis;
}
.table-container {
  flex:1;
  overflow:auto;
  flex-direction: column;
  background: #f3f5f7;
}
a:hover{
  text-decoration: none;
}
.content {
  flex: 1 0 auto;
}
.sub-footer {
  flex: 0 0 auto;
}
/*滚动条样式*/
.report-nav::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
.report-nav::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #909399;
}
</style>
